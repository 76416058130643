
import { defineComponent } from "vue";
import UserIndex from "@/components/pages/user-index.vue";

export default defineComponent({
  name: "user-management-system-users",
  components: {
    UserIndex,
  },
});
